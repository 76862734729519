// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-article-template-component-js": () => import("./../../../src/components/templates/ArticleTemplateComponent.js" /* webpackChunkName: "component---src-components-templates-article-template-component-js" */),
  "component---src-components-templates-category-template-component-js": () => import("./../../../src/components/templates/CategoryTemplateComponent.js" /* webpackChunkName: "component---src-components-templates-category-template-component-js" */),
  "component---src-components-templates-page-template-component-js": () => import("./../../../src/components/templates/PageTemplateComponent.js" /* webpackChunkName: "component---src-components-templates-page-template-component-js" */),
  "component---src-components-templates-tag-template-component-js": () => import("./../../../src/components/templates/TagTemplateComponent.js" /* webpackChunkName: "component---src-components-templates-tag-template-component-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

