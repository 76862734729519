module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Integral Service","short_name":"Integral Service","description":"Studio Web Lyonnais pour vos applications sur-mesure","start_url":"/","background_color":"#e0e0e0","theme_color":"#c62828","display":"minimal-ui","icon":"src/assets/medias/logos/logo-integral-small.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"299474ae0a7dab93506759f290fee87f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["en","fr"],"defaultLanguage":"fr","siteUrl":"http://localhost:8000/"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
